import { useContext } from 'react';

import { SidebarContext } from 'src/contexts/SidebarContext';

import { alpha, Box, darken, Drawer, lighten, styled, useTheme } from '@mui/material';

import Scrollbar from 'src/components/Scrollbar';
import SidebarMenu from './SidebarMenu';
import { useSelector } from 'react-redux';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
  width: 210px;
  min-width: ${theme.sidebar.width};
  color: ${theme.colors.alpha.trueWhite[70]};
  position: relative;
  z-index: 7;
  height: 100%;
  padding-bottom: 40px;
  `
);

function Sidebar() {
  const { users } = useSelector((state: any) => state.user);
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block'
          },
          minWidth: users.detail.role === 'GA' ? '270px' : 'none',
          position: 'fixed',
          left: 0,
          top: 0,
          background: theme.palette.mode === 'dark' ? alpha(lighten(theme.header.background, 0.1), 0.5) : darken(theme.colors.alpha.black[100], 0.5),
          boxShadow: theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
        }}
      >
        <Scrollbar>
          <Box mt={3} mb={3}>
            <Box
              mx={2}
              sx={{
                width: 52
              }}
            >
              {/* <Logo /> */}
            </Box>
          </Box>
          {/* <Divider
            sx={{
              my: theme.spacing(3),
              mx: theme.spacing(2),
              background: theme.colors.alpha.trueWhite[10]
            }}
          />
          {/* <SidebarTopSection /> 
          <Divider
            sx={{
              my: theme.spacing(3),
              mx: theme.spacing(2),
              background: theme.colors.alpha.trueWhite[10]
            }}
          /> */}
          <SidebarMenu />
        </Scrollbar>
        {/* <Divider
          sx={{
            background: theme.colors.alpha.trueWhite[10]
          }}
        />
        <SidebarFooter /> */}
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background: theme.palette.mode === 'dark' ? theme.colors.alpha.white[100] : darken(theme.colors.alpha.black[100], 0.5)
          }}
        >
          <Scrollbar>
            <Box mt={3}>
              <Box
                mx={2}
                sx={{
                  width: 52
                }}
              >
                {/* <Logo /> */}
              </Box>
            </Box>
            {/* <Divider
              sx={{
                my: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10]
              }}
            /> */}
            {/* <SidebarTopSection /> */}
            {/* <Divider
              sx={{
                my: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10]
              }}
            /> */}
            <SidebarMenu />
          </Scrollbar>
          {/* <SidebarFooter /> */}
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
