import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
// import ReactGA from 'react-ga';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { SnackbarProvider } from 'notistack';
import { useAuth0 } from '@auth0/auth0-react';
import { CssBaseline } from '@mui/material';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

// todo: don't delete needed in future
// import jwt_decode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSession, getUser } from './slices/user';
import ThemeProvider from './theme/ThemeProvider';
import cuRoutes from 'src/router/cuRoutes/cuRoutes';
import gaRoutes from 'src/router/gaRoutes/gaRoutes';
import lsRoutes from 'src/router/lsRoutes/lsRoutes';
import publicRoutes from 'src/router/public/index';
import AppInit from './components/AppInit';
import jwt_decode from 'jwt-decode';
import CommonLoader from './components/_shared/CommonLoader/CommonLoader';
import { useAppContext } from './utils/AppContextUtils';
import { isValidJwtFormat } from './utils/CommonUtils';
import * as analytics from './utils/ga4';
import useLocalStorageListener from './hooks/useLocalStorageListener';

// this key should move to env file
// ReactGA.initialize('UA-292259291-1');

//log
function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const gaRoute = useRoutes(gaRoutes);
  const cuRoute = useRoutes(cuRoutes);
  const lsRoute = useRoutes(lsRoutes);
  const publicRoute = useRoutes(publicRoutes);
  const dispatch = useDispatch();
  let content = null;
  const { user, isAuthenticated, isLoading, getIdTokenClaims, logout } = useAuth0();
  const { users } = useSelector((state: any) => state.user);
  const { stateValue } = useAppContext();
  const [cookies] = useCookies(['cookieConsent']);
  useLocalStorageListener('sessionStorage');

  useEffect(() => {
    const token = localStorage.getItem('idToken');
    // Check if the current path is "/callback"
    if (token && location.pathname === '/callback' && location.search) {
      // Remove query params by navigating to the same path without the search
      navigate(location.pathname, { replace: true });
      // window.location.reload();
    }
  }, [navigate, location]);

  useEffect(() => {
    if (cookies.cookieConsent === 'accept_all_cookies') {
      // ReactGA.initialize('G-44V6XKLEQ0');
      // ReactGA.set({ page: location.pathname });
      // ReactGA.pageview(window.location.pathname + window.location.search);
      analytics.init();
      const path = location.pathname + location.search;
      analytics.sendPageview(path);
    }
  }, [location]);

  const checkTokenExpiry = () => {
    const token = localStorage.getItem('idToken');
    if (!token) {
      // Token is not available, perform logout
      // logout();
      return;
    }
    if (!isValidJwtFormat(token)) {
      localStorage.clear();
      window.location.reload();
    }

    const decodedToken: any = jwt_decode(token);
    const expiryTime = decodedToken?.exp;
    const currentTimestamp = Math.floor(Date.now() / 1000); // Current timestamp in seconds
    const remainingTime = expiryTime - currentTimestamp;

    if (remainingTime <= 10) {
      // Token has expired, perform logout
      localStorage.clear();
    } else {
      // Set a timer to automatically logout when the token expires
      // const timeout = setTimeout(logout, remainingTime * 1000); // Convert seconds to milliseconds
      // // Clear the timer when the component unmounts or when token changes
      // return () => clearTimeout(timeout);
    }
  };

  const handleLogout = async (): Promise<void> => {
    const sessionStorage = JSON.parse(localStorage.getItem('sessionStorage'));
    try {
      const sessionIds = {
        session_id: sessionStorage?._id
      };
      let res = await dispatch<any>(deleteSession({ sessionIds: sessionIds }));
      if (res?.error) {
        return;
      } else {
        logout({
          logoutParams: {
            returnTo: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URI
          }
        });
        localStorage.clear();
      }
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    if (isLoading) return;
    // todo: don't delete needed in future
    checkTokenExpiry();

    // if (!user) {
    //   localStorage.clear();
    // }
    if (localStorage.getItem('idToken')) {
      // const token = localStorage.getItem('idToken');
      // let decodedToken: any = jwt_decode(token);

      // let dateNow = new Date();

      // if (decodedToken.exp < dateNow.getTime()) {
      // }
      const token = localStorage.getItem('idToken');
      const decodedToken: any = jwt_decode(token);
      const expirationTime = decodedToken.exp;

      // Compare current time with the token's expiration time
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

      if (currentTime > expirationTime) {
        localStorage.clear();
        window.location.reload();
      }
    }
    if (!user) {
      // localStorage.clear();
      return;
    }
    const initialCall = async () => {
      // const token = await getAccessTokenSilently();
      const accessToken = await getIdTokenClaims();
      if (!accessToken) {
        return;
      }
      localStorage.setItem('idToken', accessToken.__raw);
      const userId = accessToken.sub.split('|')[1];
      localStorage.setItem('loggedUserId', userId);
      await dispatch<any>(getUser({ userId: userId }));

      const token = accessToken.__raw;
      if (token) {
        const decodedToken: any = jwt_decode(token);
        const expiryTime = decodedToken?.exp;
        const checkExpiry = setInterval(() => {
          const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
          const timeDifference = expiryTime - currentTime;
          if (timeDifference < 300) {
            // 5 minutes = 300 seconds
            // Log out user
            handleLogout();
            clearInterval(checkExpiry); // Stop the interval
          }
        }, 1000); // Check every second

        return () => clearInterval(checkExpiry);
      }
    };

    initialCall();
  }, [user, isLoading]);

  // todo: don't delete needed in future

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  if (isAuthenticated || (localStorage.getItem('idToken') && localStorage.getItem('loggedUserId'))) {
    if (user && users) {
      if (users && users?.detail.role === 'LS') {
        content = lsRoute;
      } else if (users && users?.detail.role === 'CU') {
        content = cuRoute;
      } else if (users && users?.detail.role === 'GA') {
        content = gaRoute;
      }
    }
  } else {
    content = publicRoute;
  }

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <CommonLoader isLoading={stateValue?.isLoading} loadWithText={stateValue?.loadWithText} />
          <CssBaseline />
          {content ? content : <AppInit />}
          {/* {user ?  users?.detail.role ==="LS"? lsRoute :  users?.detail.role ==="CU"? cuRoute : users?.detail.role ==="GA" ? gaRoute : <AppInit /> : publicRoute } */}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
