import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { alpha, Avatar, Box, Button, Divider, IconButton, ListItemText, MenuItem, MenuList, Popover, styled, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { deleteSession } from 'src/slices/user';
import useNotification from 'src/hooks/useNotification';

const UserBoxButton = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  padding: 0;
  height: ${theme.spacing(4)};
  margin-left: ${theme.spacing(1)};
  border-radius: ${theme.general.borderRadiusLg};
  &:hover{
          background: none ! important;
          color: none ! important;
      }
`
);

const UserAvatar = styled(Avatar)(
  ({ theme }) => `
        height: 100%;
        width: 100%;
        border: 1px solid ${theme.colors.secondary.dark};
`
);

const MenuListWrapperPrimary = styled(MenuList)(
  ({ theme }) => `
  padding: ${theme.spacing(2)};

  & .MuiMenuItem-root {
      border-radius: 50px;
      padding: ${theme.spacing(1, 1, 1, 2.5)};
      min-width: 200px;
      margin-bottom: 2px;
      position: relative;
      color: ${theme.colors.alpha.black[100]};

      &.Mui-selected,
      &:hover,
      &.MuiButtonBase-root:active {
          background: ${theme.colors.primary.lighter};
          color: ${theme.colors.primary.main};
      }

      &:last-child {
          margin-bottom: 0;
      }
    }
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${alpha(theme.colors.alpha.black[100], 0.08)};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.secondary.light}
`
);

function HeaderUserbox() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [sendNotification] = useNotification();
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const { users } = useSelector((state: any) => state.user);
  const sessionStorage = JSON.parse(localStorage.getItem('sessionStorage'));

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      const sessionIds = {
        session_id: sessionStorage?._id
      };
      let res = await dispatch<any>(deleteSession({ sessionIds: sessionIds }));
      if (res?.error) {
        sendNotification({ message: res?.payload?.message, variant: 'error' });
      } else {
        logout({
          logoutParams: {
            returnTo: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URI
          }
        });
        localStorage.clear();
      }
    } catch (err) {
      return err;
    }
  };

  const handleNavigation = (action: string) => {
    if (action === 'Payments') {
      navigate('/legal-service/payments');
      handleClose();
    } else if (action === 'ProfileCU') {
      navigate('/corporate/account-preview');
      handleClose();
    } else if (action === 'ProfileLS') {
      navigate('/legal-service/account-preview');
      handleClose();
    }
  };

  const renderLspMenu = () => {
    switch (true) {
      case users.detail.is_primary_user:
        return (
          <>
            <MenuListWrapperPrimary disablePadding>
              <MenuItem onClick={() => handleNavigation('Payments')}>
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'h5'
                  }}
                  primary={t('Financials')}
                />
                <ChevronRightTwoToneIcon
                  sx={{
                    color: `${theme.colors.alpha.black[30]}`,
                    opacity: 0.8
                  }}
                />
              </MenuItem>
              <MenuItem onClick={() => handleNavigation('ProfileLS')}>
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'h5'
                  }}
                  primary={t('Profile')}
                />
                <ChevronRightTwoToneIcon
                  sx={{
                    color: `${theme.colors.alpha.black[30]}`,
                    opacity: 0.8
                  }}
                />
              </MenuItem>
            </MenuListWrapperPrimary>

            <Divider />
          </>
        );
      case users.detail.is_cc_user && !users.detail.is_primary_user:
        return (
          <>
            <MenuListWrapperPrimary disablePadding>
              <MenuItem onClick={() => handleNavigation('ProfileLS')}>
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'h5'
                  }}
                  primary={t('Profile')}
                />
                <ChevronRightTwoToneIcon
                  sx={{
                    color: `${theme.colors.alpha.black[30]}`,
                    opacity: 0.8
                  }}
                />
              </MenuItem>
            </MenuListWrapperPrimary>
            <Divider />
          </>
        );
      case !users.detail.is_primary_user && !users.detail.is_cc_user:
        return null;
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        display: {
          md: 'block',
          xs: 'none'
        }
      }}
    >
      <UserBoxButton color="primary" ref={ref} onClick={handleOpen}>
        <UserAvatar alt={users?.detail.first_name} src={users?.detail.logo} />
        <ExpandMoreTwoToneIcon
          sx={{
            color: `${theme.colors.alpha.black[30]}`,
            opacity: 0.8
          }}
        />
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 210
          }}
          display="flex"
        >
          <Avatar variant="rounded" alt={users?.detail.first_name} src={users?.detail.logo} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{users?.detail.first_name}</UserBoxLabel>
            <UserBoxDescription variant="body2">{users?.detail.email}</UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0
          }}
        />
        {users?.detail?.role === 'LS' && renderLspMenu()}
        {users?.detail?.role === 'CU' && (
          <>
            <MenuListWrapperPrimary disablePadding>
              <MenuItem onClick={() => handleNavigation('ProfileCU')}>
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'h5'
                  }}
                  primary={t('Profile')}
                />
                <ChevronRightTwoToneIcon
                  sx={{
                    color: `${theme.colors.alpha.black[30]}`,
                    opacity: 0.8
                  }}
                />
              </MenuItem>
            </MenuListWrapperPrimary>

            <Divider />
          </>
        )}

        <Box m={1}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon
              sx={{
                mr: 1
              }}
            />
            {t('Sign out')}
          </Button>
        </Box>
      </Popover>
    </Box>
  );
}

export default HeaderUserbox;
