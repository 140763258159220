import type { ReactNode } from 'react';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import GavelIcon from '@mui/icons-material/Gavel';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import HelpIcon from '@mui/icons-material/Help';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BookmarkAddSharpIcon from '@mui/icons-material/BookmarkAddSharp';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;
  items?: MenuItem[];
  name: string;
}
export interface GaItems {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
}
export interface GaMenuItems {
  items: GaItems[];
}

export const menuItems: MenuItems[] = [
  {
    items: [
      {
        name: 'Dashboard',
        link: '/',
        icon: HomeOutlinedIcon
      },
      {
        name: 'Users',
        link: '/legal-service/users',
        icon: GroupsOutlinedIcon
      },
      {
        name: 'Proposal Repository',
        link: '/legal-service/proposal-list',
        icon: PlaylistAddCheckOutlinedIcon
      },
      {
        name: 'Templates Bank',
        link: '/legal-service/template-bank/list',
        icon: AccountBalanceIcon
      },
      {
        name: 'Analytics',
        link: '/legal-service/analytics',
        icon: InsertChartOutlinedRoundedIcon
      },
      {
        name: 'Help Centre',
        link: '/legal-service/help-center',
        icon: HelpIcon
      }
    ]
  }
];

export const corporateMenuItems: MenuItems[] = [
  {
    items: [
      {
        name: 'Dashboard',
        link: '/',
        icon: HomeOutlinedIcon
      },
      {
        name: 'Users',
        link: '/corporate/users',
        icon: GroupsOutlinedIcon
      },
      {
        name: 'Analytics',
        link: '/corporate/analytics',
        icon: InsertChartOutlinedRoundedIcon
      },
      {
        name: 'Services Review',
        link: '/corporate/list-review',
        icon: MiscellaneousServicesIcon
      },
      {
        name: 'Draft New RFP',
        link: '/corporate/rfp/create/conflict',
        icon: HowToRegIcon
      },
      {
        name: 'Templates Bank',
        link: '/corporate/template-bank/list',
        icon: FileCopyIcon
      },
      {
        name: 'RFP Repository',
        link: '/corporate/rfp-list',
        icon: PlaylistAddCheckOutlinedIcon
      },
      {
        name: 'Preferred/Panel Law Firms',
        link: '/corporate/preferred-law-firms',
        icon: BookmarkAddSharpIcon
      },
      {
        name: 'Help Centre',
        link: '/corporate/help-center',
        icon: HelpIcon
      }
    ]
  }
];

export const gaMenuModel: GaMenuItems[] = [
  {
    items: [
      {
        name: 'Dashboard',
        link: '/',
        icon: HomeOutlinedIcon
      },

      {
        name: 'Corporate',
        link: 'admin/corporate',
        icon: CorporateFareIcon
      },
      {
        name: 'Legal Services Providers',
        link: 'admin/legal-services-providers',
        icon: GavelIcon
      },
      {
        name: 'Payments',
        link: 'admin/financials',
        icon: MonetizationOnIcon
      },
      {
        name: 'RFPs List',
        link: 'admin/all-rfp-list',
        icon: HowToRegIcon
      }
    ]
  }
];
