import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  LegalServiceAccessCheckResponseModal,
  LegalServiceApproveDeclineModal,
  LegalServiceRequestForMissingInfoModel,
  LegalServiceSignUpModal,
  LegalServiceUploadModal,
  LegalServiceDashboardModal,
  ProposalListQueryParams,
  LegalServiceProposalModal,
  LegalServiceStatusModal,
  ProposalPartnerTitleModal,
  ProposalPartnerExperienceModal,
  ProposalPartnerRoleModal,
  ProposalUploadModal,
  PaymentStatusModal,
  LegalServiceProviderReviewModal,
  LegalServiceNonAccreditedSignUpModal
} from 'src/models/LegalServiceModal';
import { ProposalFormTypes } from 'src/content/luModule/Proposals/Modal/FormModel/ProposalModel';
import {
  AddUserDetails,
  Status,
  UserQueryParams,
  FileData,
  FileDetails,
  CuStatus,
  LSkeyTeamMembersModel,
  ChartData,
  CorporateProvider,
  IndustryEditModel
} from 'src/models/form_post';
import { apiDelete, apiGet, apiPost, apiPut } from 'src/utils/axios';
import { errorHandler } from 'src/utils/errorHandler';
import {
  getLsSignUpDetails,
  postLegalServiceApproveDecline,
  postLegalServiceRequestForMissingInfo,
  postLsInviteAccessCheck,
  postLsSignUp,
  postLsUpload,
  getProposalList,
  getProposalDetailList,
  postLsUser,
  getLSUserList,
  getAllProposalDetails,
  getExpectedFeesDetails,
  putProposalDetails,
  getProposalStatus,
  getProposalLeadPartnerTitle,
  getProposalLeadPartnerExperience,
  getProposalLeadPartnerRole,
  postProposalUpload,
  getFileDownload,
  getPaymentInfo,
  addPaymentInfo,
  updatePaymentInfo,
  putPreliminaryInfoDetails,
  getLSCodeDetails,
  getLSKeyTeamMemberScoreCard,
  getLSKeyTeamMember,
  getLegalServicesChartList,
  getPaymentList,
  postProposalTemplateUrl,
  getProposalTemplateUrl,
  deleteProposalTemplateList,
  getLegalServicesAnalyticReview,
  postLsNonAccreditedSignUp,
  updateMasterTemplateUrl,
  postProposalMasterTemplateUrl,
  getMasterTemplateDetailsUrl,
  getProposalMasterTemplateUrl,
  postProposalCloseUploadUrl,
  postLsSupport,
  getDynamicContentLSP,
  updateTemplateUrl,
  getByRfpMembersUrl,
  getLSRfpScoreCardUrl,
  getPaymentDetailByIdUrl,
  getSpecificCorporateList,
  getSpecificLSKeyTeamMember,
  getSpecificLSKeyTeamMemberScoreCard,
  putLSPProfileUpdate,
  postLspAccess,
  postLsAddMainContactPerson,
  postLsRemoveMainContactPerson,
  putProposalExport,
  getLegalServiceUserGroupUrl,
  getLspUserGroupDetailUrl,
  postLspUserGroupUrl,
  putLspUserGroupUrl,
  getLspUserGroupUserUrl,
  deleteLspUserGroupUrl,
  removeLspUserUserGroupUrl,
  putLspUserGroupUpdateStatusUrl,
  addRemoveAdminLspUserUserGroupUrl,
  deleteUserUrl,
  getPastProposalListUrl
} from 'src/utils/pathConst';
import { PaymentDetails } from 'src/models/rfp';
import { CorporateRequestForMissingInfoModel, CorporaterTableInfo, CRQueryParams } from 'src/models/select';

// TODO: need to update seprate file
export type ErrorInformation = {
  message: string;
  status: number;
};

export interface SubValue {
  id: number;
  label: string;
  name: string;
  isChecked: boolean;
}

type LegalServiceState = {
  loading: boolean;
  error: ErrorInformation;
  getLSChartData: ChartData;
  getlSproviderReviewData: any;
  legalServiceAccessCheckResponse: LegalServiceAccessCheckResponseModal;
  lspUserGroupState: any;
  getDynamicData: any;
  getSpecificCorporateList: any;
  legalServiceSignUpData: LegalServiceSignUpModal;
  legalServiceInviteFormData: object;
  legalServiceOrgIndustriesFormData: any;
  legalServiceOrgPracticesFormData: object;
  legalServiceOrgJurisdictionsFormData: object;
  legalServiceOrgPricingFormData: object;
  legalServiceOrgFileFormData: object;
  legalServiceOrgFileCommentFormData: object;
  legalServiceApprovalDeclineResponse: LegalServiceApproveDeclineModal;
  LegalServiceRequestForMissingInfoResponse: LegalServiceRequestForMissingInfoModel;
  subOptions: null | SubValue[];
  user_details: AddUserDetails;
  getUserValues: Status;
  RFPProposalDetail: LegalServiceProposalModal;
  legalServiceProposalDetail: LegalServiceProposalModal;
  userGroupData: any;
  expectedFeesDetails: LegalServiceStatusModal;
  Proposal: any;
  UpdateIndustryDetails: any;
  UpdateJurisdictionDetails: any;
  ProposalStatusDetails: LegalServiceStatusModal;
  params: CRQueryParams;
  corporaterTableInfo: CorporaterTableInfo;
  proposalPartnerTitleModal: ProposalPartnerTitleModal;
  proposalPartnerExperienceModal: ProposalPartnerExperienceModal;
  proposalPartnerRoleModal: ProposalPartnerRoleModal;
  getEmailValue: Status;
  getFileValues: FileData;
  paymentInfo: any;
  paymentSelection: any;
  StatusDetail: Status;
  getLSScoreCardData: Status;
  getLSKeyTeamData: Status;
  getTeamMemberData: CuStatus;
  getSpecificTeamMemberData: CuStatus;
  deleteTemplate: any;
};

const initialState: LegalServiceState = {
  loading: true,
  error: null,
  params: null,
  getLSChartData: null,
  UpdateIndustryDetails: null,
  UpdateJurisdictionDetails: null,
  getlSproviderReviewData: null,
  StatusDetail: null,
  corporaterTableInfo: null,
  deleteTemplate: null,
  getTeamMemberData: null,
  getSpecificTeamMemberData: null,
  legalServiceAccessCheckResponse: null,
  lspUserGroupState: null,
  getDynamicData: null,
  getSpecificCorporateList: null,
  legalServiceSignUpData: null,
  legalServiceApprovalDeclineResponse: null,
  LegalServiceRequestForMissingInfoResponse: null,
  legalServiceInviteFormData: {
    invite_email: '',
    ls_provider_name: '',
    companyAddress: '',
    contact: '',
    companyPersonEmail: '',
    companyLocation: '',
    firstname: '',
    lastname: '',
    zip: '',
    city: '',
    country: '',
    communication_email: '',
    cc_email: [''],
    file: ''
  },
  legalServiceOrgIndustriesFormData: {
    initialValue: [
      {
        label: 'All',
        name: 'all',
        checked: false
      },
      {
        label: 'Agriculture',
        name: 'agriculture',
        checked: false
      },
      {
        label: 'Consumer',
        name: 'consumer',
        checked: false
      },
      {
        label: 'Energy & Resources',
        name: 'energy_resources',
        checked: false
      },
      {
        label: 'Financial Services',
        name: 'financial_services',
        checked: false
      },
      {
        label: 'Government',
        name: 'government',
        checked: false
      },
      {
        label: 'Industrials',
        name: 'industrials',
        checked: false
      },
      {
        label: 'Life Sciences & Healthcare',
        name: 'life_sciences_healthcare',
        checked: false
      },
      {
        label: 'Real Estate',
        name: 'real_estate',
        checked: false
      },
      {
        label: 'TMT',
        name: 'tmt',
        checked: false
      },
      {
        label: 'Transportation & Logistics',
        name: 'transportation_logistics',
        checked: false
      },
      {
        label: 'Other',
        name: 'other',
        checked: false,
        subOptions: [
          {
            label: 'Comments',
            name: 'comment',
            value: ''
          }
        ]
      }
    ],
    comment: ''
  },
  legalServiceOrgPracticesFormData: {
    initialValue: [
      {
        label: 'All',
        name: 'all',
        checked: false
      },
      {
        label: 'Commercial contracts',
        name: 'commercial_contracts',
        checked: false
      },
      {
        label: 'Competition',
        name: 'competition',
        checked: false
      },
      {
        label: 'Corporate M&A',
        name: 'corporate_ma',
        checked: false
      },
      {
        label: 'Data protection & privacy',
        name: 'data_protection_privacy',
        checked: false
      },
      {
        label: 'Employment',
        name: 'employment',
        checked: false
      },
      {
        label: 'Financing & capital markets',
        name: 'financing_capital_markets',
        checked: false
      },
      {
        label: '(Infrastructure) project & financing',
        name: 'projects_financing',
        checked: false
      },
      {
        label: 'IP',
        name: 'ip',
        checked: false
      },
      {
        label: 'IT',
        name: 'it',
        checked: false
      },
      {
        label: 'Funds formation',
        name: 'funds_formation',
        checked: false
      },
      {
        label: 'Funds Investment',
        name: 'funds_investment',
        checked: false
      },
      {
        label: 'Litigation',
        name: 'litigation',
        checked: false
      },
      {
        label: 'Arbitration',
        name: 'arbitration',
        checked: false
      },
      {
        label: 'Restructuring',
        name: 'restructuring',
        checked: false
      },
      {
        label: 'Insolvency',
        name: 'insolvency',
        checked: false
      },
      {
        label: ' Regulatory',
        name: 'regulatory',
        checked: false,
        subOptions: [
          {
            label: 'All',
            name: 'all',
            checked: false
          },
          {
            label: 'Finance Services',
            name: 'financial_services',
            checked: false
          },
          {
            label: 'Medical Services',
            name: 'medical_services',
            checked: false
          },
          {
            label: 'Energy',
            name: 'energy',
            checked: false
          },
          {
            label: 'Water',
            name: 'water',
            checked: false
          },
          {
            label: 'Telecommunications',
            name: 'telecommunications',
            checked: false
          },
          {
            label: 'Transport',
            name: 'transport',
            checked: false
          },
          {
            label: 'Media',
            name: 'media',
            checked: false
          },
          {
            label: 'Defence',
            name: 'defence',
            checked: false
          },
          {
            label: 'Food/Agriculture',
            name: 'food_agriculture',
            checked: false
          },
          {
            label: 'Other',
            name: 'other',
            checked: false,
            other: {
              value: false,
              comment: ''
            }
          }
        ]
      },
      {
        label: ' Tax',
        name: 'tax',
        checked: false,
        subOptions: [
          {
            label: 'All',
            name: 'all',
            checked: false
          },
          {
            label: 'Transactional tax',
            name: 'transactional_tax',
            checked: false
          },
          {
            label: 'Tax investigation & dispute',
            name: 'tax_investigation_dispute',
            checked: false
          },
          {
            label: 'Transfer pricing',
            name: 'transfer_pricing',
            checked: false
          },
          {
            label: 'Indirect tax',
            name: 'indirect_tax',
            checked: false
          },
          {
            label: 'Tax planning',
            name: 'tax_planning',
            checked: false
          },
          {
            label: 'Tax policy',
            name: 'tax_policy',
            checked: false
          },
          {
            label: 'Other',
            name: 'other',
            checked: false,
            other: {
              value: false,
              comment: ''
            }
          }
        ]
      },
      {
        label: 'Other',
        name: 'other',
        checked: false
      }
    ],
    otherComment: '',
    comment: ''
  },
  legalServiceOrgJurisdictionsFormData: {
    country: [],
    comment: ''
  },
  legalServiceOrgPricingFormData: {
    initialValue: [
      {
        label: 'All',
        name: 'all',
        checked: false
      },
      {
        label: 'Estimate',
        name: 'estimate',
        checked: false
      },
      {
        label: 'Fixed fee',
        name: 'fixed_fee',
        checked: false
      },
      {
        label: 'Capped fee',
        name: 'capped_fee',
        checked: false
      },
      {
        label: 'Hourly rate fee',
        name: 'hourly_rate_fee',
        checked: false
      },
      {
        label: 'Blended rate',
        name: 'blended_rate',
        checked: false
      },
      {
        label: 'Contingent fee',
        name: 'contingent_fee',
        checked: false
      },
      {
        label: 'Abort discount',
        name: 'abort_discount',
        checked: false
      },
      {
        label: 'Success Fee',
        name: 'success_fee',
        checked: false
      },
      {
        label: 'Reverse Auction (To be conducted by Corporate outside of Zaven)',
        name: 'reverse_auction',
        checked: false
      },
      {
        label: 'Free advice up to a certain amount',
        name: 'free_advice_amount',
        checked: false
      },
      {
        label: 'Retainer (general/project based)',
        name: 'retainer_general_project',
        checked: false
      },
      {
        label: 'Budget for project',
        name: 'budget_for_project',
        checked: false
      },
      {
        label: 'Other',
        name: 'other',
        checked: false
      }
    ],
    otherComment: '',
    comment: ''
  },
  legalServiceOrgFileFormData: {
    itSecurityPackage: [],
    dataProtectionPolicy: [],
    latestCsrReport: [],
    professionalIndemnityInsurance: [],
    diversityPolicy: [],
    conflictCheckPolicy: [],
    esgPolicy: [],
    businessEngagementTc: []
  },
  legalServiceOrgFileCommentFormData: {
    it_security_package: '',
    data_protection_policy: '',
    latest_csr_report: '',
    professional_indemnity_insurance: '',
    diversity_policy: '',
    conflict_check_policy: '',
    report_to_third_party: {
      value: false,
      comment: ''
    },
    external_validation: {
      value: false,
      comment: 'test comment'
    }
  },
  subOptions: [],
  user_details: null,
  getUserValues: null,
  RFPProposalDetail: null,
  legalServiceProposalDetail: null,
  userGroupData: null,
  expectedFeesDetails: null,
  Proposal: null,
  ProposalStatusDetails: null,
  proposalPartnerTitleModal: null,
  proposalPartnerExperienceModal: null,
  proposalPartnerRoleModal: null,
  getEmailValue: null,
  getFileValues: null,
  paymentInfo: null,
  paymentSelection: null,
  getLSScoreCardData: null,
  getLSKeyTeamData: null
};

export const postLsAccessCheck = createAsyncThunk<
  LegalServiceAccessCheckResponseModal,
  { legalServiceAccessCheckParam },
  {
    rejectValue: ErrorInformation;
  }
>('LegalServiceConnect/postLsAccessCheck', async ({ legalServiceAccessCheckParam }, { rejectWithValue }) => {
  try {
    const response = await apiPost(`${postLsInviteAccessCheck}`, legalServiceAccessCheckParam);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getLSChartDetails = createAsyncThunk<
  CorporateProvider,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getLegalServicesChartList', async (params: ChartData, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getLegalServicesChartList}?legal_service_id=${params.legal_service_id}&corporate_id=${params.corporate_id}&status=${params.status}&from_date=${params.from_date}&to_date=${params.to_date}`
    );

    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

//Todo: Specific Key team member

export const getSpecificLSTeamMembers = createAsyncThunk<
  CuStatus,
  { legal_service_id?; corporate_id },
  {
    rejectValue: ErrorInformation;
  }
>('select/getSpecificLSKeyTeamMember', async ({ legal_service_id, corporate_id }, { rejectWithValue }) => {
  try {
    const getTeamResponse = await apiGet(`${getSpecificLSKeyTeamMember}/${corporate_id}/${legal_service_id}`);
    return getTeamResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getLSTeamMembers = createAsyncThunk<
  CuStatus,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getLSKeyTeamMember', async (params: LSkeyTeamMembersModel, { rejectWithValue }) => {
  try {
    const getTeamResponse = await apiGet(`${getLSKeyTeamMember}/${params._id}`);
    return getTeamResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

// export const getByRfpMembers = createAsyncThunk<
//   CuStatus,
//   { params },
//   {
//     rejectValue: ErrorInformation;
//   }
// >('select/getByRfpMembers', async (params: any, { rejectWithValue }) => {
//   try {
//     const getTeamResponse = await apiGet(`${getByRfpMembersUrl}/${params.legal_service_id}/${params.user_id}`);
//     return getTeamResponse.data;
//   } catch (error) {
//     return rejectWithValue(errorHandler(error));
//   }
// });

export const getByRfpMembers = createAsyncThunk<
  Status,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getByRfpMembers', async (params: any, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getByRfpMembersUrl}/${params.legal_service_id}/${params.user_id}`);

    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getLsSignUpDetail = createAsyncThunk<
  LegalServiceSignUpModal,
  { id },
  {
    rejectValue: ErrorInformation;
  }
>('LegalServiceConnect/getLsSignUpDetail', async ({ id }, { rejectWithValue }) => {
  try {
    const response = await apiGet(`${getLsSignUpDetails}/${id}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const postLsFileUpload = createAsyncThunk<
  LegalServiceUploadModal,
  { file },
  {
    rejectValue: ErrorInformation;
  }
>('LegalServiceConnect/postLsFileUpload', async ({ file }, { rejectWithValue }) => {
  try {
    const response = await apiPost(`${postLsUpload}`, file);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const postLsSignUpCall = createAsyncThunk<
  LegalServiceSignUpModal,
  { formValue },
  {
    rejectValue: ErrorInformation;
  }
>('LegalServiceConnect/postLsSignUp', async ({ formValue }, { rejectWithValue }) => {
  try {
    const response = await apiPost(`${postLsSignUp}`, formValue);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const postLsNonAccreditedSignUpCall = createAsyncThunk<
  LegalServiceNonAccreditedSignUpModal,
  { formValue },
  {
    rejectValue: ErrorInformation;
  }
>('LegalServiceConnect/postLsNonAccreditedSignUp', async ({ formValue }, { rejectWithValue }) => {
  try {
    const response = await apiPost(`${postLsNonAccreditedSignUp}`, formValue);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getProposalDetails = createAsyncThunk<
  LegalServiceDashboardModal,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getProposalList', async (params: ProposalListQueryParams, { rejectWithValue }) => {
  try {
    const getProposalResponse = await apiGet(
      `${getProposalList}/${params.legal_service_id}?status=${params.status}&sortBy=${params.sortBy}&sortDir=${params.sortDir}&limit=${params.limit}&offset=${params.offset}&search_string=${params.search_string}&is_dashboard=${params.is_dashboard}`
    );
    return getProposalResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getLegalServicesProviderReviewDetails = createAsyncThunk<
  LegalServiceProviderReviewModal,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getLegalServicesAnalyticReview', async (params: ProposalListQueryParams, { rejectWithValue }) => {
  try {
    const getProposalResponse = await apiGet(
      `${getLegalServicesAnalyticReview}?legal_service_provider_id=${params.legal_service_provider_id}&lead_or_key_team_member_id=${params.lead_or_key_team_member_id}&offset=${params.offset}&limit=${params.limit}&search_string=${params.search_string}`
    );
    return getProposalResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const legalServiceApproveDecline = createAsyncThunk<
  LegalServiceApproveDeclineModal,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/legalServiceApproveDecline', async (params, { rejectWithValue }) => {
  try {
    const getResponse = await apiPost(`${postLegalServiceApproveDecline}`, params);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const legalServiceSendMessage = createAsyncThunk<
  LegalServiceRequestForMissingInfoModel,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/CorporateRequestForMissingInfoModel', async (params, { rejectWithValue }) => {
  try {
    const getResponse = await apiPost(`${postLegalServiceRequestForMissingInfo}`, params);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const PostUserDetails = createAsyncThunk<
  AddUserDetails,
  { user_details: any },
  {
    rejectValue: ErrorInformation;
  }
>('post/user', async ({ user_details }, { rejectWithValue }) => {
  try {
    const response = await apiPost(`${postLsUser}`, user_details);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getUserEmailData = createAsyncThunk<
  Status,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getUserEmailData', async (params: any, { rejectWithValue }) => {
  try {
    const getTeamResponse = await apiGet(`${getLSUserList}/${params.legal_service_id}?limit=${params.limit}&offset=${params.offset}`);
    return getTeamResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getUserData = createAsyncThunk<
  Status,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getUserList', async (params: UserQueryParams, { rejectWithValue }) => {
  try {
    const getTeamResponse = await apiGet(
      `${getLSUserList}/${params.legal_service_id}?limit=${params.limit}&offset=${params.offset}&search_string=${params.search_string}&sortDir=${params.sortDir}&sortBy=${params.sortBy}`
    );
    return getTeamResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getRFPProposalDetail = createAsyncThunk<
  LegalServiceProposalModal,
  { queryParam },
  {
    rejectValue: ErrorInformation;
  }
>('select/getProposalDetailList', async ({ queryParam }, { rejectWithValue }) => {
  try {
    // const getTeamResponse = await apiGet(`${getProposalDetailList}/${corporateId}/${rfpId}/${Id}`);
    const getTeamResponse = await apiGet(`${getProposalDetailList}/${queryParam.corporate_id}/${queryParam.rfp_id}`);
    return getTeamResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getAllProposalValue = createAsyncThunk<
  LegalServiceProposalModal,
  { queryParam },
  {
    rejectValue: ErrorInformation;
  }
>('select/getAllProposalDetails', async ({ queryParam }, { rejectWithValue }) => {
  try {
    // const getTeamResponse = await apiGet(`${getProposalDetailList}/${corporateId}/${rfpId}/${Id}`);
    const getProposalResponse = await apiGet(`${getAllProposalDetails}/${queryParam.legal_service_id}/${queryParam.proposal_id}`);
    return getProposalResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getExpectedFees = createAsyncThunk<
  LegalServiceProposalModal,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getExpectedFeesDetails', async ({}, { rejectWithValue }) => {
  try {
    const ExpectedFees = await apiGet(`${getExpectedFeesDetails}`);
    return ExpectedFees.data.detail;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getProposalPartnerTitle = createAsyncThunk<
  ProposalPartnerTitleModal,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getProposalPartnerTitle', async ({}, { rejectWithValue }) => {
  try {
    // const getTeamResponse = await apiGet(`${getProposalDetailList}/${corporateId}/${rfpId}/${Id}`);
    const response = await apiGet(`${getProposalLeadPartnerTitle}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getFileDetails = createAsyncThunk<
  FileData,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getFileDownload', async (params: FileDetails, { rejectWithValue }) => {
  try {
    const getFileResponse = await apiGet(`${getFileDownload}?key=${params.key}&actual_name=${params.actual_name}`);
    return getFileResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getProposalPartnerExperience = createAsyncThunk<
  ProposalPartnerExperienceModal,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getProposalPartnerExperience', async ({}, { rejectWithValue }) => {
  try {
    // const getTeamResponse = await apiGet(`${getProposalDetailList}/${corporateId}/${rfpId}/${Id}`);
    const response = await apiGet(`${getProposalLeadPartnerExperience}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getProposalPartnerRole = createAsyncThunk<
  ProposalPartnerRoleModal,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getProposalPartnerRole', async ({}, { rejectWithValue }) => {
  try {
    // const getTeamResponse = await apiGet(`${getProposalDetailList}/${corporateId}/${rfpId}/${Id}`);
    const response = await apiGet(`${getProposalLeadPartnerRole}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const ProposalStatus = createAsyncThunk<
  LegalServiceStatusModal,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getProposalStatus', async ({}, { rejectWithValue }) => {
  try {
    const ProposalDetails = await apiGet(`${getProposalStatus}`);
    return ProposalDetails.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const putConflictCheck = createAsyncThunk<
  LegalServiceProposalModal,
  { queryParam; Proposal },
  {
    rejectValue: ErrorInformation;
  }
>('post/putProposalDetails', async ({ queryParam, Proposal }, { rejectWithValue }) => {
  try {
    const response = await apiPut(`${putProposalDetails}/${queryParam.legal_service_id}/${queryParam.proposal_id}`, Proposal);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const updateMasterTemplate = createAsyncThunk<
  LegalServiceProposalModal,
  { queryParam; Proposal },
  {
    rejectValue: ErrorInformation;
  }
>('post/updateMasterTemplate', async ({ queryParam, Proposal }, { rejectWithValue }) => {
  try {
    const response = await apiPut(`${updateMasterTemplateUrl}/${queryParam}`, Proposal);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const updateTemplate = createAsyncThunk<
  LegalServiceProposalModal,
  { queryParam; Proposal },
  {
    rejectValue: ErrorInformation;
  }
>('post/updateMasterTemplate', async ({ queryParam, Proposal }, { rejectWithValue }) => {
  try {
    const response = await apiPut(`${updateTemplateUrl}/${queryParam}`, Proposal);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const createMasterTemplate = createAsyncThunk<
  LegalServiceProposalModal,
  { Proposal },
  {
    rejectValue: ErrorInformation;
  }
>('post/createMasterTemplate', async ({ Proposal }, { rejectWithValue }) => {
  try {
    const response = await apiPost(`${postProposalMasterTemplateUrl}`, Proposal);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const putPreliminaryInfo = createAsyncThunk<
  LegalServiceProposalModal,
  { queryParam; Proposal },
  {
    rejectValue: ErrorInformation;
  }
>('post/putPreliminaryInfo', async ({ queryParam, Proposal }, { rejectWithValue }) => {
  try {
    const response = await apiPut(`${putPreliminaryInfoDetails}/${queryParam.legal_service_id}/${queryParam.proposal_id}`, Proposal);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const postproposalFileUploads = createAsyncThunk<
  ProposalUploadModal,
  { file },
  {
    rejectValue: ErrorInformation;
  }
>('LegalServiceConnect/postproposalFileUploads', async ({ file }, { rejectWithValue }) => {
  try {
    const response = await apiPost(`${postProposalUpload}`, file);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const postProposalFileClone = createAsyncThunk<
  ProposalUploadModal,
  { params },
  {
    rejectValue: ErrorInformation;
  }
>('LegalServiceConnect/postProposalFileClone', async ({ params }, { rejectWithValue }) => {
  try {
    const response = await apiPost(`${postProposalCloseUploadUrl}`, params);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getPaymentUserInfo = createAsyncThunk<
  PaymentStatusModal,
  { param },
  {
    rejectValue: ErrorInformation;
  }
>('select/getPaymentUserInfo', async ({ param }, { rejectWithValue }) => {
  try {
    // const ProposalDetails = await apiGet(`${getPaymentInfo}/${param._id}/${param.legal_service_id}`);
    const ProposalDetails = await apiGet(`${getPaymentInfo}/${param.primary_user_id}`);
    return ProposalDetails.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const addPaymentUserInfo = createAsyncThunk<
  PaymentStatusModal,
  { param },
  {
    rejectValue: ErrorInformation;
  }
>('select/addPaymentUserInfo', async ({ param }, { rejectWithValue }) => {
  try {
    const ProposalDetails = await apiPost(`${addPaymentInfo}`, param);
    return ProposalDetails.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const updatePaymentUserInfo = createAsyncThunk<
  PaymentStatusModal,
  { param },
  {
    rejectValue: ErrorInformation;
  }
>('select/updatePaymentUserInfo', async ({ param }, { rejectWithValue }) => {
  try {
    const ProposalDetails = await apiPut(`${updatePaymentInfo}`, param);
    return ProposalDetails.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const removePaymentUserInfo = createAsyncThunk<
  PaymentStatusModal,
  { param },
  {
    rejectValue: ErrorInformation;
  }
>('select/removePaymentUserInfo', async ({ param }, { rejectWithValue }) => {
  try {
    const ProposalDetails = await apiDelete(`${updatePaymentInfo}`, param);
    return ProposalDetails.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getLSstatus = createAsyncThunk<
  Status,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getLSCodeDetails', async ({}, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getLSCodeDetails}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getLSScoreCard = createAsyncThunk<
  Status,
  { params },
  {
    rejectValue: ErrorInformation;
  }
>('select/getLSScoreCard', async (params: any, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getLSKeyTeamMemberScoreCard}?user_id=${params?.params.user_id}&corporate_id=${params?.params.corporate_id}`);

    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

//Todo: Key Team Member Specific Score Card for LSP
export const getSpecificLSScoreCard = createAsyncThunk<
  Status,
  { params },
  {
    rejectValue: ErrorInformation;
  }
>('select/getSpecificLSKeyTeamMemberScoreCard', async (params: any, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getSpecificLSKeyTeamMemberScoreCard}?legal_service_id=${params?.params.legal_service_id}&user_id=${params?.params.user_id}&corporate_id=${params?.params.corporate_id}`
    );

    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getLSRfpScoreCard = createAsyncThunk<
  Status,
  { params },
  {
    rejectValue: ErrorInformation;
  }
>('select/getLSRfpScoreCard', async (params: any, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getLSRfpScoreCardUrl}?rfp_id=${params?.params.rfp_id}&legal_service_id=${params?.params.legal_service_id}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getPaymentDetailList = createAsyncThunk<
  PaymentDetails,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getPaymentDetailList', async (params: any, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getPaymentList}/${params.legal_service_id}?offset=${params.offset}&limit=${params.limit}&sortBy=${params.sortBy}&sortDir=${params.sortDir}`
    );
    return getResponse.data.detail[0];
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getPaymentDetailById = createAsyncThunk<
  PaymentDetails,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getPaymentDetailById', async (params: any, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getPaymentDetailByIdUrl}/${params.user_id}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getProposalListTemplate = createAsyncThunk<
  LegalServiceProposalModal,
  { queryParam },
  {
    rejectValue: ErrorInformation;
  }
>('select/getProposalListTemplate', async ({ queryParam }, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getProposalTemplateUrl}?search_string=${queryParam.search_string}&limit=${queryParam.limit}&offset=${queryParam.offset}`
    );
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

//Todo: Past Fee Proposal
export const getPastProposalList = createAsyncThunk<
  LegalServiceProposalModal,
  { queryParam },
  {
    rejectValue: ErrorInformation;
  }
>('select/getPastProposalList', async ({ queryParam }, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getPastProposalListUrl}/${queryParam.proposal_id}?search_string=${queryParam.search_string}&limit=${queryParam.limit}&offset=${queryParam.offset}&sortDir=${queryParam.sortDir}&sortBy=${queryParam.sortBy}`
    );
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getProposalListMasterTemplate = createAsyncThunk<
  LegalServiceProposalModal,
  { queryParam },
  {
    rejectValue: ErrorInformation;
  }
>('select/getProposalListMasterTemplate', async ({ queryParam }, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getProposalMasterTemplateUrl}/${queryParam?.legalServiceId}?search_string=${queryParam.search_string}&limit=${queryParam.limit}&offset=${
        queryParam.offset
      }&from_date=${queryParam.from_date || ''}&to_date=${queryParam.to_date || ''}`
    );
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const postCreateTemplate = createAsyncThunk<
  LegalServiceProposalModal,
  { requestData: any },
  {
    rejectValue: ErrorInformation;
  }
>('postCreateTemplate', async ({ requestData }, { rejectWithValue }) => {
  try {
    const getResponse = await apiPost(`${postProposalTemplateUrl}`, requestData);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getTemplateDetailsById = createAsyncThunk<
  Status,
  { queryParam },
  {
    rejectValue: ErrorInformation;
  }
>('select/getTemplateDetailsById', async ({ queryParam }, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getProposalTemplateUrl}/${queryParam.templateId}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

//Todo: Get Past Proposal by id
export const getPastProposalById = createAsyncThunk<
  Status,
  { queryParam },
  {
    rejectValue: ErrorInformation;
  }
>('select/getPastProposalById', async ({ queryParam }, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getPastProposalListUrl}/${queryParam.pastProposal}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getMasterTemplateDetailsById = createAsyncThunk<
  Status,
  { queryParam },
  {
    rejectValue: ErrorInformation;
  }
>('select/getMasterTemplateDetailsById', async ({ queryParam }, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getMasterTemplateDetailsUrl}/${queryParam.templateId}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const deleteProposalTemplateListView = createAsyncThunk<
  Status,
  { queryParam: any; deleteTemplate: any },
  {
    rejectValue: ErrorInformation;
  }
>('select/deleteTemplateListView', async ({ queryParam, deleteTemplate }, { rejectWithValue }) => {
  try {
    const getResponse = await apiDelete(`${deleteProposalTemplateList}/${queryParam?.proposal_template_bank_id}`, deleteTemplate);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const lsFaqSendMessage = createAsyncThunk<
  CorporateRequestForMissingInfoModel,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/CorporateRequestForMissingInfoModel', async (params, { rejectWithValue }) => {
  try {
    const getResponse = await apiPost(`${postLsSupport}`, params);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const lspMainContactPersonAccess = createAsyncThunk<
  Status,
  { idParams: any },
  {
    rejectValue: ErrorInformation;
  }
>('select/lspMainContactPersonAccess', async ({ idParams }, { rejectWithValue }) => {
  try {
    const getResponse = await apiPost(`${postLspAccess}`, idParams);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getDynamicContentPreview = createAsyncThunk<
  Status,
  { LegalServicesId? },
  {
    rejectValue: ErrorInformation;
  }
>('select/getDynamicContentLSP', async ({ LegalServicesId }, { rejectWithValue }) => {
  try {
    const getTeamResponse = await apiGet(`${getDynamicContentLSP}/${LegalServicesId}`);
    return getTeamResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

//Todo : Specific Corporate List

export const getSpecificCorporates = createAsyncThunk<
  CorporaterTableInfo,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/corporaterTableInfo', async (params: CRQueryParams, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getSpecificCorporateList}?search_string=${params.search_string}&status=${params.status}&sortBy=${params.sortBy}&sortDir=${params.sortDir}&limit=${params.limit}&offset=${params.offset}&legal_service_id=${params.legal_service_id}`
    );
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

//Todo : Legal Services Industry Update api

export const putUpdateUserProfile = createAsyncThunk<
  IndustryEditModel,
  { LegalServicesId; userId; formValue },
  {
    rejectValue: ErrorInformation;
  }
>('select/putLSPProfileUpdate', async ({ LegalServicesId, userId, formValue }, { rejectWithValue }) => {
  try {
    const getResponse = await apiPut(`${putLSPProfileUpdate}/${LegalServicesId}/${userId}`, formValue);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getAddMainContactPerson = createAsyncThunk<
  Status,
  { body: any },
  {
    rejectValue: ErrorInformation;
  }
>('select/getAddMainContactPerson', async ({ body }, { rejectWithValue }) => {
  try {
    const getResponse = await apiPost(`${postLsAddMainContactPerson}`, body);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});
export const getRemoveMainContactPerson = createAsyncThunk<
  Status,
  { body: any },
  {
    rejectValue: ErrorInformation;
  }
>('select/getRemoveMainContactPerson', async ({ body }, { rejectWithValue }) => {
  try {
    const getResponse = await apiPost(`${postLsRemoveMainContactPerson}`, body);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});
export const putExportProposal = createAsyncThunk<
  Status,
  { body: any; proposalId: any; lspId: any },
  {
    rejectValue: ErrorInformation;
  }
>('select/putExportProposal', async ({ body, proposalId, lspId }, { rejectWithValue }) => {
  try {
    const getResponse = await apiPut(`${putProposalExport}/${lspId}/${proposalId}`, body);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getLegalServiceUserGroup = createAsyncThunk<
  any,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getLegalServiceUserGroup', async (params: any, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getLegalServiceUserGroupUrl}/${params.legal_service_id}?sortBy=${params.sortBy}&sortDir=${params.sortDir}&limit=${params.limit}&offset=${params.offset}&status=${params.status}&search_string=${params.search_string}&legal_service_id=${params.legal_service_id}`
    );
    return getResponse.data.detail[0];
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getLspUserGroupDetail = createAsyncThunk<
  any,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getLspUserGroupDetail', async (params: any, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getLspUserGroupDetailUrl}/${params.userGroupId}`);
    return getResponse.data.detail[0];
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const putLspUserGroup = createAsyncThunk<
  AddUserDetails,
  { bodyParams: any },
  {
    rejectValue: ErrorInformation;
  }
>('select/putLspUserGroup', async ({ bodyParams }, { rejectWithValue }) => {
  try {
    const response = await apiPut(`${putLspUserGroupUrl}/${bodyParams.userGroupId}`, bodyParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const postLspUserGroup = createAsyncThunk<
  AddUserDetails,
  { bodyParams: any },
  {
    rejectValue: ErrorInformation;
  }
>('select/postLspUserGroup', async ({ bodyParams }, { rejectWithValue }) => {
  try {
    const response = await apiPost(`${postLspUserGroupUrl}`, bodyParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getLspUserGroupUser = createAsyncThunk<
  any,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getLspUserGroupUser', async (params: any, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getLspUserGroupUserUrl}/${params.legal_service_id}?sortBy=${params.sortBy}&sortDir=${params.sortDir}&limit=${params.limit}&offset=${params.offset}&status=${params.status}&search_string=${params.search_string}&legal_service_id=${params.legal_service_id}`
    );
    return getResponse.data.detail[0];
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const deleteLspUserGroup = createAsyncThunk<
  AddUserDetails,
  { bodyParams: any },
  {
    rejectValue: ErrorInformation;
  }
>('select/deleteLspUserGroup', async ({ bodyParams }, { rejectWithValue }) => {
  try {
    const response = await apiDelete(`${deleteLspUserGroupUrl}`, bodyParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const removeLspUserUserGroup = createAsyncThunk<
  AddUserDetails,
  { bodyParams: any },
  {
    rejectValue: ErrorInformation;
  }
>('select/removeLspUserUserGroup', async ({ bodyParams }, { rejectWithValue }) => {
  try {
    const response = await apiDelete(`${removeLspUserUserGroupUrl}/${bodyParams.user_group_id}/${bodyParams.member_id}`, {});
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const putLspUserGroupUpdateStatus = createAsyncThunk<
  AddUserDetails,
  { bodyParams: any },
  {
    rejectValue: ErrorInformation;
  }
>('select/putLspUserGroupUpdateStatus', async ({ bodyParams }, { rejectWithValue }) => {
  try {
    const response = await apiPut(`${putLspUserGroupUpdateStatusUrl}/${bodyParams.userGroupId}`, bodyParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const addRemoveAdminLspUserUserGroup = createAsyncThunk<
  AddUserDetails,
  { bodyParams: any },
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/addRemoveAdminLspUserUserGroup', async ({ bodyParams }, { rejectWithValue }) => {
  try {
    const response = await apiPut(`${addRemoveAdminLspUserUserGroupUrl}/${bodyParams.user_group_id}/${bodyParams.member_id}`, {});
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const deleteLspUser = createAsyncThunk<
  any,
  { bodyParams: any },
  {
    rejectValue: ErrorInformation;
  }
>('select/deleteLspUser', async ({ bodyParams }, { rejectWithValue }) => {
  try {
    const response = await apiDelete(`${deleteUserUrl}`, bodyParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const legalServiceSlice = createSlice({
  name: 'LegalServiceConnect',
  initialState,
  reducers: {
    updateLearningForm: (state, action: PayloadAction<any>) => {
      state.legalServiceInviteFormData = action.payload;
    },
    updateOrgIndustriesForm: (state, action: PayloadAction<any>) => {
      state.legalServiceOrgIndustriesFormData = action.payload;
    },
    updateOrgPracticesForm: (state, action: PayloadAction<any>) => {
      state.legalServiceOrgPracticesFormData = action.payload;
    },
    updateOrgJurisdictionForm: (state, action: PayloadAction<any>) => {
      state.legalServiceOrgJurisdictionsFormData = action.payload;
    },
    updateOrgPricingForm: (state, action: PayloadAction<any>) => {
      state.legalServiceOrgPricingFormData = action.payload;
    },
    updateOrgFileForm: (state, action: PayloadAction<any>) => {
      state.legalServiceOrgFileFormData = action.payload;
    },
    updateOrgFileCommentForm: (state, action: PayloadAction<any>) => {
      state.legalServiceOrgFileCommentFormData = action.payload;
    },
    handleResetLegalServiceProposalDetail: (state) => {
      state.legalServiceProposalDetail = null;
    },
    updateLspUserGroupState: (state, action: PayloadAction<any>) => {
      state.userGroupData = action.payload;
    }
  },

  extraReducers: {
    //ls signup access check
    [postLsAccessCheck.pending.type]: (state) => {
      state.loading = true;
    },
    [postLsAccessCheck.fulfilled.type]: (state, action: PayloadAction<LegalServiceAccessCheckResponseModal | null>) => {
      state.legalServiceAccessCheckResponse = action.payload;
      state.loading = false;
    },
    [postLsAccessCheck.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getLegalServiceUserGroup.pending.type]: (state) => {
      state.loading = true;
    },
    [getLegalServiceUserGroup.fulfilled.type]: (state, action: PayloadAction<any | null>) => {
      state.lspUserGroupState = action.payload;
      state.loading = false;
    },
    [getLegalServiceUserGroup.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getDynamicContentPreview.pending.type]: (state) => {
      state.loading = true;
    },
    [getDynamicContentPreview.fulfilled.type]: (state, action: PayloadAction<Status | null>) => {
      state.getDynamicData = action.payload;
      state.loading = false;
    },
    [getDynamicContentPreview.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getSpecificCorporates.pending.type]: (state) => {
      state.loading = true;
    },
    [getSpecificCorporates.fulfilled.type]: (state, action: PayloadAction<Status | null>) => {
      state.getSpecificCorporateList = action.payload;
      state.loading = false;
    },
    [getSpecificCorporates.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getLSChartDetails.pending.type]: (state) => {
      state.loading = true;
    },
    [getLSChartDetails.fulfilled.type]: (state, action: PayloadAction<ChartData | null>) => {
      state.getLSChartData = action.payload;
      state.loading = false;
    },
    [getLSChartDetails.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getLegalServicesProviderReviewDetails.pending.type]: (state) => {
      state.loading = true;
    },
    [getLegalServicesProviderReviewDetails.fulfilled.type]: (state, action: PayloadAction<ChartData | null>) => {
      state.getLSChartData = action.payload;
      state.loading = false;
    },
    [getLegalServicesProviderReviewDetails.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [putUpdateUserProfile.pending.type]: (state) => {
      state.loading = true;
    },
    [putUpdateUserProfile.fulfilled.type]: (state, action: PayloadAction<any | null>) => {
      state.UpdateJurisdictionDetails = action.payload;
      state.loading = false;
    },
    [putUpdateUserProfile.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getLSTeamMembers.pending.type]: (state) => {
      state.loading = true;
    },
    [getLSTeamMembers.fulfilled.type]: (state, action: PayloadAction<Status | null>) => {
      state.getTeamMemberData = action.payload;
      state.loading = false;
    },
    [getLSTeamMembers.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getSpecificLSTeamMembers.pending.type]: (state) => {
      state.loading = true;
    },
    [getSpecificLSTeamMembers.fulfilled.type]: (state, action: PayloadAction<Status | null>) => {
      state.getSpecificTeamMemberData = action.payload;
      state.loading = false;
    },
    [getSpecificLSTeamMembers.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getLSScoreCard.pending.type]: (state) => {
      state.loading = true;
    },
    [getLSScoreCard.fulfilled.type]: (state, action: PayloadAction<Status | null>) => {
      state.getLSScoreCardData = action.payload;
      state.loading = false;
    },
    [getLSScoreCard.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    // [getLSKeyTeamMembers.pending.type]: (state) => {
    //   state.loading = true;
    // },
    // [getLSKeyTeamMembers.fulfilled.type]: (state, action: PayloadAction<Status | null>) => {
    //   state.getLSKeyTeamData = action.payload;
    //   state.loading = false;
    // },
    // [getLSKeyTeamMembers.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // },

    //ls signup data check
    [getLsSignUpDetail.pending.type]: (state) => {
      state.loading = true;
    },
    [getLsSignUpDetail.fulfilled.type]: (state, action: PayloadAction<LegalServiceSignUpModal | null>) => {
      state.legalServiceSignUpData = action.payload;
      state.loading = false;
    },
    [getLsSignUpDetail.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getLSstatus.pending.type]: (state) => {
      state.loading = true;
    },
    [getLSstatus.fulfilled.type]: (state, action: PayloadAction<Status | null>) => {
      state.StatusDetail = action.payload;
      state.loading = false;
    },
    [getLSstatus.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    //Ls User Details list
    [getUserData.pending.type]: (state) => {
      state.loading = true;
    },
    [getUserData.fulfilled.type]: (state, action: PayloadAction<Status | null>) => {
      state.getUserValues = action.payload;
      state.loading = false;
    },
    [getUserData.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getUserEmailData.pending.type]: (state) => {
      state.loading = true;
    },
    [getUserEmailData.fulfilled.type]: (state, action: PayloadAction<Status | null>) => {
      state.getEmailValue = action.payload;
      state.loading = false;
    },
    [getUserEmailData.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    //ls approval or decline
    [legalServiceApproveDecline.pending.type]: (state) => {
      state.loading = true;
    },
    [legalServiceApproveDecline.fulfilled.type]: (state, action: PayloadAction<LegalServiceApproveDeclineModal | null>) => {
      state.legalServiceApprovalDeclineResponse = action.payload;
      state.loading = false;
    },
    [legalServiceApproveDecline.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    //ls Request For Missing Info
    [legalServiceSendMessage.pending.type]: (state) => {
      state.loading = true;
    },
    [legalServiceSendMessage.fulfilled.type]: (state, action: PayloadAction<LegalServiceRequestForMissingInfoModel | null>) => {
      state.LegalServiceRequestForMissingInfoResponse = action.payload;
      state.loading = false;
    },
    [legalServiceSendMessage.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Legal Services Add user slices
    [PostUserDetails.pending.type]: (state) => {
      state.loading = true;
    },
    [PostUserDetails.fulfilled.type]: (state, action: PayloadAction<AddUserDetails | null>) => {
      state.user_details = action.payload;
      state.loading = false;
    },
    [PostUserDetails.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getRFPProposalDetail.pending.type]: (state) => {
      state.loading = true;
    },
    [getRFPProposalDetail.fulfilled.type]: (state, action: PayloadAction<LegalServiceProposalModal | null>) => {
      state.RFPProposalDetail = action.payload;
      state.loading = false;
    },
    [getRFPProposalDetail.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getAllProposalValue.pending.type]: (state) => {
      state.loading = true;
    },
    [getAllProposalValue.fulfilled.type]: (state, action: PayloadAction<LegalServiceProposalModal | null>) => {
      state.legalServiceProposalDetail = action.payload;
      state.loading = false;
    },
    [getAllProposalValue.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getExpectedFees.pending.type]: (state) => {
      state.loading = true;
    },
    [getExpectedFees.fulfilled.type]: (state, action: PayloadAction<LegalServiceStatusModal | null>) => {
      state.expectedFeesDetails = action.payload;
      state.loading = false;
    },
    [getExpectedFees.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    [putConflictCheck.pending.type]: (state) => {
      state.loading = true;
    },
    [putConflictCheck.fulfilled.type]: (state, action: PayloadAction<ProposalFormTypes | null>) => {
      state.Proposal = action.payload;
      state.loading = false;
    },
    [putConflictCheck.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [ProposalStatus.pending.type]: (state) => {
      state.loading = true;
    },
    [ProposalStatus.fulfilled.type]: (state, action: PayloadAction<LegalServiceStatusModal | null>) => {
      state.ProposalStatusDetails = action.payload;
      state.loading = false;
    },
    [ProposalStatus.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getProposalPartnerTitle.pending.type]: (state) => {
      state.loading = true;
    },
    [getProposalPartnerTitle.fulfilled.type]: (state, action: PayloadAction<ProposalPartnerTitleModal | null>) => {
      state.proposalPartnerTitleModal = action.payload;
      state.loading = false;
    },
    [getProposalPartnerTitle.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getProposalPartnerExperience.pending.type]: (state) => {
      state.loading = true;
    },
    [getProposalPartnerExperience.fulfilled.type]: (state, action: PayloadAction<ProposalPartnerExperienceModal | null>) => {
      state.proposalPartnerExperienceModal = action.payload;
      state.loading = false;
    },
    [getProposalPartnerExperience.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getProposalPartnerRole.pending.type]: (state) => {
      state.loading = true;
    },
    [getProposalPartnerRole.fulfilled.type]: (state, action: PayloadAction<ProposalPartnerRoleModal | null>) => {
      state.proposalPartnerRoleModal = action.payload;
      state.loading = false;
    },
    [getProposalPartnerRole.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getFileDetails.pending.type]: (state) => {
      state.loading = true;
    },
    [getFileDetails.fulfilled.type]: (state, action: PayloadAction<FileData | null>) => {
      state.getFileValues = action.payload;
      state.loading = false;
    },
    [getFileDetails.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getPaymentUserInfo.pending.type]: (state) => {
      state.loading = true;
    },
    [getPaymentUserInfo.fulfilled.type]: (state, action: PayloadAction<FileData | null>) => {
      state.paymentInfo = action.payload;
      state.loading = false;
    },
    [getPaymentUserInfo.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.paymentInfo = null;
      state.error = action.payload;
    },
    [getPaymentDetailList.pending.type]: (state) => {
      state.loading = true;
    },
    [getPaymentDetailList.fulfilled.type]: (state, action: PayloadAction<FileData | null>) => {
      state.paymentSelection = action.payload;
      state.loading = false;
    },
    [getPaymentDetailList.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    [deleteProposalTemplateListView.pending.type]: (state) => {
      state.loading = true;
    },
    [deleteProposalTemplateListView.fulfilled.type]: (state, action: PayloadAction<any | null>) => {
      state.deleteTemplate = action.payload;
      state.loading = false;
    },
    [deleteProposalTemplateListView.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});
// export const { updateLearningForm } = legalServiceSlice.actions;
// export const { updateOrgIndustriesForm } = legalServiceSlice.actions;
// export const { updateOrgPracticesForm } = legalServiceSlice.actions;
// export const { updateOrgJurisdictionForm } = legalServiceSlice.actions;
// export const { updateOrgPricingForm } = legalServiceSlice.actions;
// export const { updateOrgFileForm } = legalServiceSlice.actions;
// export const { updateOrgFileCommentForm } = legalServiceSlice.actions;
export const {
  updateOrgFileCommentForm,
  updateOrgFileForm,
  updateOrgPricingForm,
  updateOrgJurisdictionForm,
  updateLearningForm,
  updateOrgIndustriesForm,
  updateOrgPracticesForm,
  handleResetLegalServiceProposalDetail,
  updateLspUserGroupState
} = legalServiceSlice.actions;

export default legalServiceSlice;
