const modifiedResponseObject = (rfpListById) => {
  const pricing =
    (rfpListById?.detail &&
      rfpListById?.detail[0]?.pricing[0]?.ecw &&
      `
     "pricing": {
        "score": <legal_services_providerX_pricing_score>,
        "explanation": "reason_for_high_or_low_score"
      },
  `) ||
    '';

  const assumptions =
    (rfpListById?.detail &&
      rfpListById?.detail[0]?.preliminary_info[0]?.ecw?.identify_bidders &&
      `
     "assumptions_exclusions": {
        "score": <legal_services_providerX_assumptions_score>,
        "explanation": "reason_for_high_or_low_score"
      },
  `) ||
    '';

  const keyTeamMember =
    (rfpListById?.detail &&
      rfpListById?.detail[0]?.other_key_info[0]?.key_team_members?.value &&
      `
     "key_team_members": {
        "score": <legal_services_providerX_key_team_score>,
        "explanation": "reason_for_high_or_low_score"
      },
  `) ||
    '';

  const staffing =
    (rfpListById?.detail &&
      rfpListById?.detail[0]?.other_key_info[0]?.staffing?.value &&
      `
     "staffing": {
        "score": <legal_services_providerX_staffing>,
        "explanation": "reason_for_high_or_low_score"
      },
  `) ||
    '';

  const diversity =
    (rfpListById?.detail &&
      rfpListById?.detail[0]?.other_key_info[0]?.diversity?.value &&
      `
     "diversity": {
        "score": <legal_services_providerX_diversity_score>,
        "explanation": "reason_for_high_or_low_score"
      },
  `) ||
    '';

  const credentials =
    (rfpListById?.detail &&
      rfpListById?.detail[0]?.other_key_info[0]?.credentials?.value &&
      `
     "credentials": {
        "score": <legal_services_providerX_credentials_score>,
        "explanation": "reason_for_high_or_low_score"
      },
  `) ||
    '';

  const disaggregation =
    (rfpListById?.detail &&
      rfpListById?.detail[0]?.other_key_info[0]?.disaggregation?.value &&
      `
     "disaggregation": {
        "score": <legal_services_providerX_disaggregation_score>,
        "explanation": "reason_for_high_or_low_score"
      },
  `) ||
    '';

  const usp =
    (rfpListById?.detail &&
      rfpListById?.detail[0]?.other_key_info[0]?.usp?.value &&
      `
     "usp": {
        "score": <legal_services_providerX_usp_score>,
        "explanation": "reason_for_high_or_low_score"
      },
  `) ||
    '';

  const video =
    (rfpListById?.detail &&
      rfpListById?.detail[0]?.other_key_info[0]?.video?.value &&
      `
     "video": {
        "score": <legal_services_providerX_video_score>,
        "explanation": "reason_for_high_or_low_score"
      },
  `) ||
    '';

  const other =
    (rfpListById?.detail &&
      rfpListById?.detail[0]?.other_key_info[0]?.other?.value &&
      `
     "other": {
        "score": <legal_services_providerX_other_score>,
        "explanation": "reason_for_high_or_low_score"
      },
  `) ||
    '';
  return { pricing, assumptions, keyTeamMember, staffing, diversity, credentials, disaggregation, usp, video, other };
};

export const initialAiPrompt = (rfpListById, rfpValueState) => {
  console.log('rfpValueState:', rfpValueState);

  const pricingSection =
    (rfpListById?.detail &&
      rfpListById?.detail[0]?.pricing[0]?.ecw &&
      `1. Pricing:
Evaluate the competitiveness, clarity, and value for money based on the law firm's pricing model. The dynamic input parameters for pricing include:
Legal services provider 1 value: <legal_services_provider1_pricing_value>
Legal services provider 2 value: <legal_services_provider2_pricing_value>
Legal services provider 3 value: <legal_services_provider3_pricing_value>
Legal services provider 4 value: <legal_services_provider4_pricing_value>
Legal services provider 5 value: <legal_services_provider5_pricing_value>
Pricing considerations may include:
ECW (Evaluation Criteria Weighting) for Pricing: <pricing_ecw_value>
Currency
Model (Estimate, Fixed Fee, Success Fee, Abort Discount)
Total Amount & Estimate
  `) ||
    '';
  const assumptionsAndExclusionsSection =
    (rfpListById?.detail &&
      rfpListById?.detail[0]?.preliminary_info[0]?.ecw?.identify_bidders &&
      `2. Assumptions & Exclusions:
Evaluate the clarity and relevance of the law firm's assumptions and exclusions related to service delivery. The dynamic input parameters for assumptions include:
Legal services provider 1 value: <legal_services_provider1_assumptions_value>
Legal services provider 2 value: <legal_services_provider2_assumptions_value>
Legal services provider 3 value: <legal_services_provider3_assumptions_value>
Legal services provider 4 value: <legal_services_provider4_assumptions_value>
Legal services provider 5 value: <legal_services_provider5_assumptions_value>
Examples of assumptions and exclusions include:
ECW (Evaluation Criteria Weighting) for Assumptions & Exclusions: <assumptions_ecw_value>
No travel expected
Tax advice excluded
Coordination with local legal counsel
Any additional assumptions or exclusions`) ||
    '';
  const keyTeamSection =
    (rfpListById?.detail &&
      rfpListById?.detail[0]?.other_key_info[0]?.key_team_members?.value &&
      `3. Key Team Members:
Assess the qualifications, roles, and experience of the key team members proposed by the law firm. The dynamic input parameters for key team members include:
Legal services provider 1 value: <legal_services_provider1_keyteam_member_value>
Legal services provider 2 value: <legal_services_provider2_keyteam_member_value>
Legal services provider 3 value: <legal_services_provider3_keyteam_member_value>
Legal services provider 4 value: <legal_services_provider4_keyteam_member_value>
Legal services provider 5 value: <legal_services_provider5_keyteam_member_value>
This evaluation should consider:
ECW (Evaluation Criteria Weighting) for Key Team Members: <keyteam_member_ecw_value>
Title, role, years of experience, and project role
Location of Lead Partner`) ||
    '';
  const staffingSection =
    (rfpListById?.detail &&
      rfpListById?.detail[0]?.other_key_info[0]?.staffing?.value &&
      `4. Staffing:
Evaluate the staffing and project management capabilities of the proposed team for the project. The dynamic input parameters for staffing assessment include:
ECW (evaluation criteria weighting) value: <staffing_ecw_value>
Legal Services Provider 1 Staffing Value: <legal_services_provider1_staffing_value>
Legal Services Provider 2 Staffing Value: <legal_services_provider2_staffing_value>
Legal Services Provider 3 Staffing Value: <legal_services_provider3_staffing_value>
Legal Services Provider 4 Staffing Value: <legal_services_provider4_staffing_value>
Legal Services Provider 5 Staffing Value: <legal_services_provider5_staffing_value>
This evaluation should consider:
ECW (Evaluation Criteria Weighting) for Staffing: <staffing_ecw_value>
   `) ||
    '';

  const diversitySection =
    (rfpListById?.detail &&
      rfpListById?.detail[0]?.other_key_info[0]?.diversity?.value &&
      `5. Diversity:
Evaluate the firm’s commitment to diversity and inclusion, as well as its USP in the context of the legal services provided. The dynamic input parameters for diversity and USP include:
Legal services provider 1 value: <legal_services_provider1_diversity_value>
Legal services provider 2 value: <legal_services_provider2_diversity_value>
Legal services provider 3 value: <legal_services_provider3_diversity_value>
Legal services provider 4 value: <legal_services_provider4_diversity_value>
Legal services provider 5 value: <legal_services_provider5_diversity_value>
This evaluation should consider:
ECW (Evaluation Criteria Weighting) for Diversity: <diversity_ecw_value>
Specific diversity initiatives and policies `) ||
    '';
  const credentialsSection =
    (rfpListById?.detail &&
      rfpListById?.detail[0]?.other_key_info[0]?.credentials?.value &&
      `6. Credentials:
Assess the firm’s credentials (ordered by relevance and recency) and any additional information that demonstrates their expertise. The dynamic input parameters for credentials include:
Legal services provider 1 value: <legal_services_provider1_credentials_value>
Legal services provider 2 value: <legal_services_provider2_credentials_value>
Legal services provider 3 value: <legal_services_provider3_credentials_value>
Legal services provider 4 value: <legal_services_provider4_credentials_value>
Legal services provider 5 value: <legal_services_provider5_credentials_value>
This evaluation should consider:
ECW (Evaluation Criteria Weighting) for Credentials: <credentials_ecw_value>
`) ||
    '';
  const disaggregationSection =
    (rfpListById?.detail &&
      rfpListById?.detail[0]?.other_key_info[0]?.disaggregation?.value &&
      `7. Disaggregation:
Analyze the disaggregation of services, focusing on the proposed outsourcing strategies and the integration of legal technology solutions. The dynamic input parameters for disaggregated services include:
Legal services provider 1 value: <legal_services_provider1_disaggregation_value>
Legal services provider 2 value: <legal_services_provider2_disaggregation_value>
Legal services provider 3 value: <legal_services_provider3_disaggregation_value>
Legal services provider 4 value: <legal_services_provider4_disaggregation_value>
Legal services provider 5 value: <legal_services_provider5_disaggregation_value>
This evaluation should consider:
ECW (Evaluation Criteria Weighting) for Disaggregation: <disaggregation_ecw_value>
`) ||
    '';
  const uspSection =
    (rfpListById?.detail &&
      rfpListById?.detail[0]?.other_key_info[0]?.usp?.value &&
      `8. USP (What is your USP (max 300 characters) ?):
Define the Unique Selling Proposition (USP) of each legal services provider within 300 characters. Dynamic input parameters for USPs include:
Legal services provider 1 value: <legal_services_provider1_usp_value>
Legal services provider 2 value: <legal_services_provider2_usp_value>
Legal services provider 3 value: <legal_services_provider3_usp_value>
Legal services provider 4 value: <legal_services_provider4_usp_value>
Legal services provider 5 value: <legal_services_provider5_usp_value>
This evaluation should consider:
ECW (Evaluation Criteria Weighting) for USP: <usp_ecw_value>
`) ||
    '';
  const videoSection =
    (rfpListById?.detail &&
      rfpListById?.detail[0]?.other_key_info[0]?.video?.value &&
      `9. Video:
Legal service providers to provide short video in addition to information in this section “Other key information” (max 2 min). Dynamic input parameters for USPs include:
Legal services provider 1 value: <legal_services_provider1_video_value>
Legal services provider 2 value: <legal_services_provider2_video_value>
Legal services provider 3 value: <legal_services_provider3_video_value>
Legal services provider 4 value: <legal_services_provider4_video_value>
Legal services provider 5 value: <legal_services_provider5_video_value>
This evaluation should consider:
ECW (Evaluation Criteria Weighting) for Video: <video_ecw_value>
`) ||
    '';
  const otherSection =
    (rfpListById?.detail &&
      rfpListById?.detail[0]?.other_key_info[0]?.other?.value &&
      `10. Other:
Provide an overview or additional insights not covered in the main categories. Dynamic input parameters for this section include:
Legal services provider 1 value: <legal_services_provider1_other_value>
Legal services provider 2 value: <legal_services_provider2_other_value>
Legal services provider 3 value: <legal_services_provider3_other_value>
Legal services provider 4 value: <legal_services_provider4_other_value>
Legal services provider 5 value: <legal_services_provider5_other_value>
This evaluation should consider:
ECW (Evaluation Criteria Weighting) for Other: <other_ecw_value>
`) ||
    '';
  const updatedPrompt = `You are developing a recommendation engine for corporate legal teams to help them
select the most suitable law firm from a pool of bidders. The law firms have provided
information across various metrics, and the evaluation process is based on a scoring system
tied to weighted evaluation criteria. You will analyze the information to provide clear and actionable recommendations,
focusing on selecting one winning bidder while giving reasons for the rejection of other bidders.
Task:
Legal services provider 1: <legal_services_provider1_name>
Legal services provider 2: <legal_services_provider2_name>
Legal services provider 3: <legal_services_provider3_name>
Legal services provider 4: <legal_services_provider4_name>
Legal services provider 5: <legal_services_provider5_name>
Given the following metrics from multiple law firms, analyze and score them based on consolidated evaluation criteria.
Each RFP has specific requirements under Scope of Work (SOW), but the evaluation will use a unified and consolidated list to simplify the process.
Ensure that the inputs are dynamically populated using parameter names, allowing for a flexible evaluation process.
Consolidated Evaluation Criteria:
${pricingSection}
${assumptionsAndExclusionsSection}
${keyTeamSection}
${staffingSection}
${diversitySection}
${credentialsSection}
${disaggregationSection}
${uspSection}
${videoSection}
${otherSection}
Dynamic Input Parameters:
The following parameters should be dynamically populated based on the data provided for each RFP:
scope_of_work_items: A list of specific SOW items for the current RFP. This parameter will include a subset of the consolidated list mentioned above, based on the specific requirements of each RFP.
pricing_model: The pricing model proposed by each law firm (Estimate, Fixed Fee, etc.).
assumptions: A list of assumptions and exclusions provided by the firm.
key_team_members: A list of key team members with their qualifications and roles.
diversity_policies: Details about the firm's diversity policies and initiatives.
credentials: Any additional credentials and other key information relevant to the RFP.
bidders_count: The total number of participating law firms.
Output Format: JSON (for the one winning bidder and the rejected bidders)
The output should be in the form of a JSON object that includes the results for the one winning law firm and the reasons why other law firms were rejected. Each firm should have scores for each evaluation criterion, the final overall score, and two key reasons for the decision.

Output Format: JSON (for the one winning bidder and the rejected bidders)
The output should be in the form of a JSON object that includes the results for the one winning law firm and the reasons why other law firms were rejected. Each firm should have scores for each evaluation criterion, the final overall score and score should range between 0 to 5 only,
and two key reasons for the decision.
json
Copy code
{
  "bidders_count": <bidders_count>,
  "winning_bidder": {
    "law_firm_name": "Winning Firm Name",
    "final_score": <final_score>,
    "total_score": <total_score_calculation>,
    "criteria_scores": {
      ${modifiedResponseObject(rfpListById).pricing}
      ${modifiedResponseObject(rfpListById).assumptions}
      ${modifiedResponseObject(rfpListById).keyTeamMember}
      ${modifiedResponseObject(rfpListById).staffing}
      ${modifiedResponseObject(rfpListById).diversity}
      ${modifiedResponseObject(rfpListById).credentials}
      ${modifiedResponseObject(rfpListById).disaggregation}
      ${modifiedResponseObject(rfpListById).usp}
      ${modifiedResponseObject(rfpListById).video}
      ${modifiedResponseObject(rfpListById).other} 
    },
    "justification": {
      "reason_1": "First key reason why this firm was selected as the winning bidder",
      "reason_2": "Second key reason why this firm was selected as the winning bidder"
    }
  },
  "rejected_bidders": [
    {
      "law_firm_name": "Rejected Firm 1",
      "final_score": <final_score>,
      "total_score": <total_score_calculation>,
      "criteria_scores": {
        ${modifiedResponseObject(rfpListById).pricing}
        ${modifiedResponseObject(rfpListById).assumptions}
        ${modifiedResponseObject(rfpListById).keyTeamMember}
        ${modifiedResponseObject(rfpListById).staffing}
        ${modifiedResponseObject(rfpListById).diversity}
        ${modifiedResponseObject(rfpListById).credentials}
        ${modifiedResponseObject(rfpListById).disaggregation}
        ${modifiedResponseObject(rfpListById).usp}
        ${modifiedResponseObject(rfpListById).video}
        ${modifiedResponseObject(rfpListById).other}
      },
      "justification": {
        "reason_1": "First key reason why this firm,
	      "reason_2": "Second key reason why this firm
}
}

`;
  return updatedPrompt.replace(/(^[ \t]*\n)/gm, '');
};

export const tableJson = {
  details: {
    bidders_count: 3,
    winning_bidder: {
      law_firm_name: '',
      final_score: '',
      criteria_scores: {
        pricing: {
          score: '',
          explanation: ''
        },
        assumptions_exclusions: {
          score: '',
          explanation: ''
        },
        key_team_members: {
          score: '',
          explanation: ''
        },
        diversity: {
          score: '',
          explanation: ''
        },
        credentials_other: {
          score: '',
          explanation: ''
        }
      },
      justification: {
        reason_1: '',
        reason_2: ''
      }
    },
    rejected_bidders: [
      {
        law_firm_name: '',
        final_score: '',
        criteria_scores: {
          pricing: {
            score: '',
            explanation: ''
          },
          assumptions_exclusions: {
            score: '',
            explanation: ''
          },
          key_team_members: {
            score: '',
            explanation: ''
          },
          diversity: {
            score: '',
            explanation: ''
          },
          credentials_other: {
            score: '',
            explanation: ''
          }
        },
        justification: {
          reason_1: '',
          reason_2: ''
        }
      }
    ]
  }
};
