import { useEffect } from 'react';

const useLocalStorageListener = (key) => {
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (!event.key) {
        window.location.reload();
        window.location.href = '/';
      }
      if (event.key === key) {
        // Reload the page or navigate to home page
        window.location.reload();
        window.location.href = '/';
      }
    };

    // Listen for localStorage changes
    window.addEventListener('storage', handleStorageChange);

    return () => {
      // Cleanup the event listener
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key]);
};

export default useLocalStorageListener;
