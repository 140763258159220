import { useContext } from 'react';
// import HeaderSearch from './Search';
// import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { alpha, Box, Divider, lighten, Stack, styled, Tooltip, useTheme, IconButton } from '@mui/material';
// import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';

import Logo from 'src/components/Logo';

// import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import { useSelector } from 'react-redux';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 3, 0, 0)};
        right: 0;
        z-index: 10;
        background-color: ${alpha(theme.header.background, 1.0)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            // left: ${theme.sidebar.width};
            left: ${0};
            width: auto;
        }
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();

  const { users } = useSelector((state: any) => state.user);

  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      sx={{
        boxShadow:
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(lighten(theme.colors.primary.main, 0.7), 0.15)}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(theme.colors.alpha.black[100], 0.2)}, 0px 5px 22px -4px ${alpha(theme.colors.alpha.black[100], 0.1)}`
      }}
    >
      <Stack
        direction="row"
        display="flex"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        alignItems="center"
        sx={{
          ml: 0
        }}
      >
        <Logo />
        {/* <Stack direction="row">
          <Typography
            sx={{
              mt: 0.3
            }}
          >
            Project Alpha
          </Typography>
          <ExpandMoreTwoToneIcon
            sx={{
              color: `${theme.colors.alpha.black[30]}`,
              opacity: 0.8
            }}
          />
        </Stack>
        <Stack direction="row">
          <Typography
            sx={{
              mt: 0.3
            }}
          >
            Help
          </Typography>
          <ExpandMoreTwoToneIcon
            sx={{
              color: `${theme.colors.alpha.black[30]}`,
              opacity: 0.8
            }}
          />
        </Stack> */}
      </Stack>
      <Stack display="flex" alignItems="center" spacing={2} direction="row">
        {/* <TextField
          sx={{
            mt: 2,
            mb: 2
          }}
          size="small"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchTwoToneIcon />
              </InputAdornment>
            )
          }}
          placeholder="Search..."
        /> */}
        {/* <HeaderSearch /> */}
        <Divider
          sx={{
            display: {
              xs: 'none',
              md: 'block'
            }
          }}
          orientation="vertical"
          flexItem
        />
        <Box
          sx={{
            mx: 2,
            display: {
              xs: 'none',
              md: 'block'
            }
          }}
        >
          {users?.detail.first_name}
        </Box>
        {/* <Divider orientation="vertical" flexItem />
        <Box
          sx={{
            mx: 2
          }}
        >
          {users?.detail.first_name}
        </Box> */}
        <HeaderUserbox />
        <Box
          component="span"
          sx={{
            ml: 2,
            display: {
              md: 'none',
              xs: 'inline-block'
            }
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? <MenuTwoToneIcon fontSize="small" /> : <CloseTwoToneIcon fontSize="small" />}
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
    </HeaderWrapper>
  );
}

export default Header;
